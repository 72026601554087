import * as React from 'react';
import NewsFeed from './components/NewsFeed';
import Home from './components/home';
/* import { createGlobalStyle } from 'styled-components' 

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Verdana, Geneva, Tahoma, sans-serifs;
  }
`*/

const Main = () => {
  return (
    <React.Fragment>
      <NewsFeed title="mybestshop24.com"></NewsFeed>
      <Home></Home>
    </React.Fragment>

  );
}

export default Main
