
import styled from "styled-components";
import logo from '../assets/img/logo.png';


console.log(logo); // /logo.84287d09.png

function home() {
  // Import result is the URL of your image
  return <img src={logo} alt="Logo" />;
}


export default home;